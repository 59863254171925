import FormWizard from "../wizzard/components/react-form-wizzard/components/FormWizard";
import WizzardStepPersonalData from "../wizzard/steps/WizzardStepPersonalData";
import WizzardStepPayment from "../wizzard/steps/WizzardStepPayment";
import WizzardStepOrder from "../wizzard/steps/WizzardStepOrder";
import { Button, Col, Form, Row } from "react-bootstrap";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FormWizardMethods,
  FormWizardProps,
} from "../wizzard/components/react-form-wizzard/types/FormWizard";
import WizardButton from "../wizzard/components/react-form-wizzard/components/WizardButton";
import { useRecoilState } from "recoil";
import { WizzardStateType, wizzardState } from "../atoms/wizzardState";
import axios, { AxiosResponse } from "axios";

import SignalRConnector, {
  Client2GroupEventData,
  ISignalRConnector,
} from "../services/SignalRConnector";
import { AppProps } from "../App";
import Loader from "./components/Loader";
import TerminVorschlaege from "./components/TerminVorschlaege";

export type HandleInputChangeEvent =
  | ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  | { key: string; value: string | boolean };

var initPage = false;
var lastPostRequest = '';
export default function WizzardPage(props: { appProps: AppProps }) {
  const formWizardRef = useRef<FormWizardProps & FormWizardMethods>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const signalRInstanceRef = useRef<ISignalRConnector | null>(null);

  const [formData, setFormData] = useRecoilState(wizzardState);
  const [isLoading, setIsLoading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [wasValidated, setWasValidated] = useState(false);
  const [antragDocId, setAntragDocId] = useState<number | null>(null);

  useEffect(() => {
    setIsComplete(formData.completed);
  }, [formData.completed]);

  useEffect(() => {
    setAntragDocId(formData.antrag_doc_id);
  }, [formData.antrag_doc_id]);

  useEffect(() => {
    if (
      !props.appProps.serviceUrl ||
      props.appProps.serviceUrl == null ||
      props.appProps.auth == null
    )
      return;

    if (signalRInstanceRef.current) return;

    signalRInstanceRef.current = SignalRConnector(
      props.appProps.serviceUrl + "/hubs/NotificationHub",
      props.appProps.auth
    );

    // Define the event handler
    const handleClient2GroupEvent = (dataE: Client2GroupEventData) => {
      console.log("recieve:", dataE.body);
      if (
        typeof dataE.body === "object" &&
        dataE.body !== null &&
        "name" in dataE.body &&
        "value" in dataE.body
      ) {
        var body = dataE.body;

        setFormData((prevFormData: any) => ({
          ...prevFormData,
          [body.name]: body.value,
        }));
      } else {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          ...dataE.body,
        }));
      }
    };

    // Register the event handler
    signalRInstanceRef.current.events(handleClient2GroupEvent);
  }, [
    formData.current_step,
    props.appProps.auth,
    props.appProps.mode,
    props.appProps.serviceUrl,
    setFormData,
  ]);

  const saveForm = useCallback(
    async (completed: boolean) => {
      var thisPostRequest = JSON.stringify({
        completed:completed,
        formData: formData
      });
      console.log(thisPostRequest, lastPostRequest);
      if(lastPostRequest === thisPostRequest){ return;}
      lastPostRequest = thisPostRequest;
      setIsLoading(true);
      var resp = await axios.post<FormDataResponse>(
        `${props.appProps.serviceUrl}/Bvmu/Beitrittserklaerung/FormData?completed=${completed}`,
        formData,
        { headers: { Authorization: props.appProps.auth } }
      );

      setAntragDocId(resp.data.antrag_doc_id);
      setIsComplete(resp.data.completed);
      setIsLoading(false);
      setWasValidated(false);
    },
    [formData, props.appProps.auth, props.appProps.serviceUrl]
  );

  const saveFormRef = useRef(saveForm);

  useEffect(() => {    
    saveFormRef.current = saveForm;
  }, [saveForm]);

  const handleComplete = async () => {
    checkValidateTab();
    console.log("Form completed!");

    await saveFormRef?.current(true);
    await updateFormData("completed", true);
  };

  const handleTabChange = async (e: {
    prevIndex: number;
    nextIndex: number;
  }) => {
    console.log(e, formData.current_step, e.prevIndex);

    if (props.appProps.mode === "gp") return true;

    setWasValidated(false);
    
    if (formData.current_step !== e.prevIndex) {
      await updateFormData("current_step", e.prevIndex);
    }

    return true;
  };

  const checkValidateTab = () => {
    var invalidFields = document.querySelectorAll(":invalid");
    invalidFields.forEach((f) => (f as HTMLInputElement).checkValidity());

    setWasValidated(true);

    return invalidFields.length === 0;
  };

  const callWizzardHandler = async (handler: () => any, validate: boolean) => {
    if (!validate) {
      handler();
      return;
    }

    var isFormValid = checkValidateTab();

    if (isFormValid) {
      handler();
    } else {
      console.log("tab invalid");
    }

    return true;
  };

  const updateFormData = async (name: string, value: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));

    console.log(`set [${name}]: ${value}`);

    if (props.appProps.mode === "gp") return;

    await signalRInstanceRef.current?.Client2GroupExcludeSelf({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChange = async (e: HandleInputChangeEvent) => {
    if ("target" in e) {
      let {
        name,
        value,
        type,
      }: { name: string; value: string | boolean | number | null; type: string } =
        e.target;

      if ("checked" in e.target) {
        if (type === "checkbox") value = e.target.checked;
        else if (type === "radio" && !e.target.checked) return;
      }

      if (type === "number") {
        value = parseFloat(value as string);

        if (isNaN(value))
          value = null;
      }

      await updateFormData(name, value);
    } else {
      const { key, value } = e;
      await updateFormData(key, value);
    }
  };

  type FormDataResponse = WizzardStateType & { completed: boolean };

  const intiForm = useCallback(async () => {
    console.log("initFrom");
    var resp = (await axios.get(
      props.appProps.serviceUrl + "/Bvmu/Beitrittserklaerung/FormData",
      { headers: { Authorization: props.appProps.auth } }
    )) as AxiosResponse<FormDataResponse>;
    setFormData(resp.data);
    setIsLoading(false);
    initPage = true;
    setIsComplete(resp.data.completed);
  }, [props.appProps.auth, props.appProps.serviceUrl, setFormData]);

  useEffect(() => {
    intiForm();
  }, [intiForm]);

  useEffect(() => {
    formWizardRef.current?.goToTab(formData.current_step);
  }, [formData.current_step]);

  useEffect(() => {
    if (props.appProps.mode === "gp") return;
    if (formData.current_step === 0) return;
    if (initPage === false) return;
    saveFormRef?.current(false);
  }, [formData.current_step, saveFormRef, props.appProps.mode]);

  const gotoTerminVorschlaege = async () => {
    await updateFormData("current_step", 100);
  };

  const fillButtonStyle = {
    backgroundColor: "#089FE1",
    borderColor: "#089FE1",
    borderRadius: "4px",
  };

  if (formData.current_step === 100 || (formData.terminVorschlagAccepted !== null && formData.terminVorschlagAccepted !== undefined)) {
    console.log("terminVorschlagAccepted", formData.terminVorschlagAccepted, formData.current_step);
    return (
      <TerminVorschlaege
        appProps={props.appProps}
        signalRInstance={signalRInstanceRef}
        terminVorschlagAccepted={formData.terminVorschlagAccepted}
      />
    );
  }

  if (isComplete) {
    return (
      <div
        style={{
          display: "block",
          margin: "15% auto 20% auto",
          fontSize: "2rem",
          textAlign: "center",
          width: "600px",
        }}
      >
        <span style={{ color: "green", fontSize: "3rem" }}>{"\u2713"}</span>{" "}
        Antragseingabe abgeschlossen
        <p style={{ fontSize: "1rem", marginTop: "1rem" }}>
          In Kürze erhalten Sie eine E-Mail von{" "}
          <a href="mailto:bvmu@bds.nrw">bvmu@bds.nrw</a> mit einer Kopie Ihrer
          Antragsdaten und unserer Kontaktdaten.
        </p>
        {antragDocId && (  
        <p style={{ fontSize: "1rem", marginTop: "1rem" }}>
          Eine Kopie Ihrer Antragsdaten im PDF-Format können Sie{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={
              props.appProps.serviceUrl +
              `/Files/ByToken/attachment/${antragDocId}.pdf?auth=${props.appProps.auth}`
            }
          >
            hier
          </a>{" "}
          herrunterladen.
        </p>)}
        <Row className="mt-5">
          <Col>
            <Button
              onClick={() => gotoTerminVorschlaege()}
              disabled={props.appProps.mode === "gp"}
            >
              Zu den Terminvorschlägen für die BAV Beratung
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <>
      <Loader show={isLoading} />
      <Form
        noValidate
        validated={wasValidated}
        ref={formRef}
        style={{ display: isLoading ? "none" : "" }}
      >
        <FormWizard
          ref={formWizardRef}
          onComplete={handleComplete}
          onTabChange={handleTabChange}
          color="#089FE1"
          stepSize="sm"
          startIndex={formData.current_step ?? 0}
          enableAllSteps={props.appProps.mode === "gp"}
          backButtonTemplate={(handleBack) =>
            props.appProps.mode === "gp" ? (
              <></>
            ) : (
              <div className="wizard-footer-left" style={fillButtonStyle}>
                <WizardButton
                  onClick={() => callWizzardHandler(handleBack, false)}
                >
                  Zurück
                </WizardButton>
              </div>
            )
          }
          nextButtonTemplate={(handleNext) =>
            props.appProps.mode === "gp" ? (
              <></>
            ) : (
              <div className="wizard-footer-right" style={fillButtonStyle}>
                <WizardButton
                  onClick={() => callWizzardHandler(handleNext, true)}
                >
                  Weiter
                </WizardButton>
              </div>
            )
          }
          finishButtonTemplate={(handleFinish) =>
            props.appProps.mode === "gp" ? (
              <></>
            ) : (
              <div className="wizard-footer-right" style={fillButtonStyle}>
                <WizardButton
                  onClick={() => callWizzardHandler(handleFinish, true)}
                >
                  Mitgliedschaft beantragen
                </WizardButton>
              </div>
            )
          }
          footerTemplate={
            <small>
              Alle mit Sternchen
              <span className="requiredMark" /> gekennzeichneten Felder sind
              Pflichtfelder.{" "}
            </small>
          }
        >
          <FormWizard.TabContent
            title="Persönliche Angaben"
            icon="fa fa-regular fa-user"
          >
            <WizzardStepPersonalData
              handleInputChange={handleInputChange}
              appProps={props.appProps}
            />
          </FormWizard.TabContent>

          <FormWizard.TabContent
            title="Mitgliedsbeitrag"
            icon="fa fa-regular fa-credit-card"
          >
            <WizzardStepPayment
              handleInputChange={handleInputChange}
              appProps={props.appProps}
            />
          </FormWizard.TabContent>

          <FormWizard.TabContent
            title="Abschluss"
            icon="fa fa-regular fa-pen-to-square"
          >
            <WizzardStepOrder
              handleInputChange={handleInputChange}
              appProps={props.appProps}
            />
          </FormWizard.TabContent>
        </FormWizard>
      </Form>
    </>
  );
}
