import React, { useEffect, useState } from "react";
import WizzardPage from "./pages/WizzardPage";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { AppPropsState } from "./atoms/AppPropsState";
import { ErrorBoundary } from "react-error-boundary";
import { AxiosError } from "axios";

export type AppProps = {
  serviceUrl: string | null;
  mode: string | null;
  auth: string;
  showHeader: boolean | null;
};

function App(props: AppProps) {
  const [appProps, setAppProps] = useRecoilState(AppPropsState);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setAppProps(props);
  }, [props, setAppProps]);

  useEffect(() => {
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      console.error("Unhandled promise rejection:", event.reason);
      setError(event.reason); // Update the state with the error
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    return () => {
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection
      );
    };
  }, [setError]);

  const ErrorFallback = ({
    error,
    resetErrorBoundary,
  }: {
    error: Error;
    resetErrorBoundary: () => void;
  }) => {
    var msg = error.message;
    if (error instanceof AxiosError) {
      if (error.response?.status === 403) {
        return (
          <Alert
            variant="danger"
            className="w-25 text-center"
            style={{ margin: "10% auto" }}
          >
            <Alert.Heading>Verweigertert</Alert.Heading>
            <hr />
            <p>Sie sind nicht berechtigt, diese Seite zu sehen. <small>(403)</small></p>
          </Alert>
        );
      }
    }

    return (
      <Alert
        variant="danger"
        className="w-25 text-center"
        style={{ margin: "10% auto" }}
      >
        <Alert.Heading>Ups! Etwas ging schief.</Alert.Heading>
        <hr />
        <pre>{msg}</pre>
        <div className="d-flex justify-content-end">
          <Button variant="dark" size="sm" onClick={resetErrorBoundary}>Ignorieren</Button>
        </div>
      </Alert>
    );
  };

  if (error) {
    return (
      <ErrorFallback error={error} resetErrorBoundary={() => setError(null)} />
    );
  }

  if (!appProps.serviceUrl || appProps.serviceUrl === null) return <></>;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="App">
        {!props.showHeader && (
          <header>
            <h1 className="mb-4">Beitrittserklärung</h1>

            <Row>
              <Col>
                <h3>zum</h3>
                <h2>
                  Bund der Selbständigen
                  <br />
                  Landesverband NRW e.V. (BDS)
                </h2>
                <h6>Ferdinand-Porsche-Str. 1, 59439 Holzwickede</h6>
              </Col>
              <Col>
                <h3>zur</h3>
                <h2>
                  Bundesvereinigung mittelständischer
                  <br />
                  Unternehmer e.V. (BVMU)
                </h2>
                <h6>Ferdinand-Porsche-Str. 1, 59439 Holzwickede</h6>
              </Col>
            </Row>
          </header>
        )}

        <WizzardPage appProps={props} />
      </div>
    </ErrorBoundary>
  );
}

export default App;
