import React from "react";
import ReactDOM from "react-dom/client";
import App, { AppProps } from "./App";
import { RecoilRoot } from "recoil";

import "./wizzard/components/react-form-wizzard/index.css";
import "./styles/index.scss";
import "./styles/App.scss";

//import sheet from "./styles/index.scss" with { type: "css" };

class BvmuBeitrittserklaerungAntragApp extends HTMLElement {
  private root: ReactDOM.Root | null = null;
  private queryParameters = new URLSearchParams(window.location.search);
 
  getParam(name: string) {
    var val = this.getAttribute(name);

    if (val === undefined || val === null || val === "")
      val = this.queryParameters.get(name);

    return val;
  }

  connectedCallback() {
    
//console.log(JSON.stringify(sheet), "sheet")
    const initParams = {
      serviceUrl: this.getParam("serviceUrl"),
      mode: this.getParam("mode"),
      auth: this.getParam("auth"),
      showHeader: this.getParam("showHeader"),
    } as AppProps;

    /*if (!this.shadowRoot) {
      this.attachShadow({ mode: "open" });
    }*/

    const container = document.createElement("div");
    container.classList.add("bvmu-beitrittserklaerung-antrag-container");
    //this.shadowRoot!.appendChild(container);
    this.appendChild(container);

    // // Inject bundled styles into shadow DOM
    // const styleEl = document.createElement("style");
    // styleEl.textContent = `
    //   ${indexStyles}
    //   ${appStyles}
    // `;
    // this.shadowRoot!.appendChild(styleEl);

    this.root = ReactDOM.createRoot(container);
    this.root.render(
      <>
        <React.StrictMode>
          <RecoilRoot>
            <App {...initParams} />
            {!initParams.showHeader && <footer>
              <ul className="nav justify-content-center mb-3">
                <li className="nav-item"> <a href="https://www.bds-nrw.de/kontakt.php" target="_footer" className="nav-link px-2 text-body-secondary">Kontakt</a></li>
                <li className="nav-item"><a href="https://www.bds-nrw.de/impressum.php" target="_footer" className="nav-link px-2 text-body-secondary">Impressum</a></li>
                <li className="nav-item"><a href="https://www.bds-nrw.de/datenschutz.php" target="_footer" className="nav-link px-2 text-body-secondary">Datenschutz</a></li>
              </ul>
            </footer>}
          </RecoilRoot>
        </React.StrictMode>
      </>
    );
  }

  disconnectedCallback() {
    if (this.root) {
      this.root.unmount();
    }
  }
}

customElements.define(
  "bvmu-beitrittserklaerung-antrag",
  BvmuBeitrittserklaerungAntragApp
);


export default BvmuBeitrittserklaerungAntragApp;