import * as signalR from "@microsoft/signalr";
import { WizzardStateType } from '../atoms/wizzardState';

export type Client2GroupEventData = {
  body: { name: string; value: string | boolean | number | null } | WizzardStateType;
};

export type SignalRConnectorEvents = (
  onClient2Group: (data: Client2GroupEventData) => void
) => void;

export interface ISignalRConnector {
  connection: signalR.HubConnection;
  events: SignalRConnectorEvents;
  Client2GroupExcludeSelf: (messages: any) => Promise<void>;
}

class SignalRConnector implements ISignalRConnector {
  public connection: signalR.HubConnection;

  public events: (
    onClient2Group: (data: Client2GroupEventData) => void
  ) => void;

  static instance: SignalRConnector;

  constructor(url: string, token: string) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        //skipNegotiation: true,
        //transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => token,
        withCredentials: false
      })
      .withAutomaticReconnect()
      .build();

    this.connection.start().catch((err) => { console.log(err); throw Error("Verbindung zum Gesprächspartner konnte nicht hergestellt werden.");});

    this.events = (onClient2Group) => {
      this.connection.on(
        "NotificationHub:PublishData:Client2Group",
        (dataE: Client2GroupEventData) => {
          onClient2Group(dataE);
        }
      );
    };
  }

  public Client2GroupExcludeSelf = (messages: any) => {
    if (this.connection.state !== signalR.HubConnectionState.Connected) {
      console.warn(
        `can't send '${JSON.stringify(
          messages
        )}' because connection is in state ${this.connection?.state}`
      );
      return Promise.resolve();
    }

    return this.connection
      .send("Client2GroupExcludeSelf", messages)
      .then(() => console.log("send", messages));
  };

  public static getInstance(url: string, token: string): SignalRConnector {
    if (!SignalRConnector.instance)
      SignalRConnector.instance = new SignalRConnector(url, token);
    return SignalRConnector.instance;
  }
}

export default SignalRConnector.getInstance;
