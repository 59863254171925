import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useRecoilState } from "recoil";
import { wizzardState } from "../../atoms/wizzardState";
import { AppProps } from '../../App';
import moment from "moment";
import { isNullOrEmpty } from "../../utils/utils";
import { HandleInputChangeEvent } from "../../pages/WizzardPage";
import patterns from "../../utils/patterns";

export default function WizzardStepPersonalData(props: {
  handleInputChange: (e: HandleInputChangeEvent) => any,
  appProps: AppProps
}) {
  var [formData] = useRecoilState(wizzardState);

  const onBirthdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let date = new Date(Date.parse(e.currentTarget.value));

    let today = new Date();
    let toOld = new Date();
    toOld.setFullYear(today.getFullYear() - 120);

    e.currentTarget.setCustomValidity(
      date > today || date < toOld
        ? "Bitte geben Sie ein gütliges Geburtsdatum ein."
        : ""
    );

    props.handleInputChange(e);
  };

  function formatGebDate(): string | number | string[] | undefined {
    var m = moment(formData.gebdate);
    console.log(formData.gebdate, m)
    return m.isValid() ? m.format('YYYY-MM-DD') : formData.gebdate;
  }

  return (
    <>
      <Row className="mb-3">
        <Col className="mr-1 col-12 col-md-6">
          <Row className="mb-3 form-block">
            <Form.Group as={Col} className="col-lg-12">
              <Form.Label>Firma</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Firma"
                minLength={3}
                maxLength={255}
                name="fname"
                value={formData.fname ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier Ihren Firmennamen ein.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="col-3">
              <Form.Label>Anrede</Form.Label>
              <Form.Select
                required
                name="anrede"
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
                value={formData.anrede ?? ""}
              >
                <option disabled value="">Anrede</option>
                <option value="1">Herr</option>
                <option value="2">Frau</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Bitte wählen Sie aus wie Sie angesprochen werden möchten.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Vorname</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Vorname"
                pattern={patterns.name()}
                minLength={2}
                maxLength={255}
                name="vname"
                value={formData.vname ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier Ihren Vornamen ein.<br /><small>(Abkürzungen sind nicht erlaubt)</small>
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Nachname</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nachname"
                pattern={patterns.name()}
                minLength={2}
                maxLength={255}
                name="nname"
                value={formData.nname ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier Ihren Nachnamen ein.<br /><small>(Abkürzungen sind nicht erlaubt)</small>
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3 form-block">
            <Form.Group as={Col} className="col-lg-12">
              <Form.Label>Straße</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Straße"
                minLength={3}
                maxLength={255}
                name="street"
                value={formData.street ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier die Straße Ihres Firmensitzes ein.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>PLZ</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="PLZ"
                pattern="\d{5}"
                name="plz"
                value={formData.plz ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier die Postleitzahl Ihres Firmensitzes ein.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Ort</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Ort"
                pattern={patterns.ort()}
                minLength={3}
                maxLength={255}
                name="ort"
                value={formData.ort ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier den Ortsnames Ihres Firmensitzes ein.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Col>
        <Col className="col-12 col-md-6">
          <Row className="mb-3 form-block">
            <Form.Group as={Col}>
              <Form.Label>Mobilfunknummer</Form.Label>
              <Form.Control
                required={isNullOrEmpty(formData.tel)}
                type="text"
                placeholder="Mobile Telefonnummer"
                pattern={patterns.phone()}
                minLength={5}
                maxLength={20}
                name="mobil"
                value={formData.mobil?.replace(/[^\d+]/g, '') ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier eine gültige Mobilfunknummer ein.<br/>
                 <small>(z.B. +491604567 oder 0163 4444 5555).</small>
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Festnetznummer</Form.Label>
              <Form.Control
                required={isNullOrEmpty(formData.mobil)}
                type="text"
                placeholder="Festnetznummer"
                pattern={patterns.phone()}
                minLength={5}
                maxLength={20}
                name="tel"
                value={formData.tel?.replace(/[^\d+]/g, '') ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier eine gültige Telefonnummer ein.<br/>
                <small>(z.B. +491234567 oder 044 4444 5555).</small>
              </Form.Control.Feedback>
            </Form.Group>
            <div className="w-100"></div>
            <Form.Group as={Col}>
              <Form.Label>E-Mail</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="ihre@email.adresse"
                name="email"
                value={formData.email ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier eine gültige E-Mail-Adresse ein.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="w-100"></div>
            <Form.Group as={Col}>
              <Form.Label>Anzahl sozialversicherungspflichtig Beschäftigte</Form.Label>
              <Form.Control
                required
                type="number"
                min="0"
                max="10000"
                step="1"
                placeholder="Anzahl sozialversicherungspflichtig Beschäftigte"
                pattern="\d{1,4}"
                name="anzahl_mitarbeiter"
                value={formData.anzahl_mitarbeiter ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie die Anzahl der sozialversicherungspflichtig Beschäftigten in Ihrem Unternehmen ein.<br /><small>(Als Selbständiger geben Sie bitte 0 an. Maximal 10.000)</small>
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 form-block">
            <Form.Group as={Col} className="col-lg-6">
              <Form.Label>Geburtsdatum</Form.Label>
              <Form.Control
                required
                type="date"
                name="gebdate"
                value={formatGebDate() ?? ""}
                onChange={onBirthdayChange}
                // onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                //   e.target.showPicker()
                // }
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier ein gültiges Geburtsdatum ein.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Branche</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Branche"
                minLength={3}
                maxLength={255}
                name="branche"
                value={formData.branche ?? ""}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier die Branche ein in der ihr Unternehmen
                tätig ist.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Col>
      </Row>
    </>
  );
}
