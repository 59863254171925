import axios, { AxiosResponse } from "axios";
import { MutableRefObject, useEffect, useMemo, useState } from "react";
import Loader from "./Loader";
import { AppProps } from "../../App";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import moment from "moment";
import { ISignalRConnector } from "../../services/SignalRConnector";
import { wizzardState } from "../../atoms/wizzardState";
import { useRecoilState } from "recoil";
import { isNullOrEmpty } from "../../utils/utils";

export type TerminVorschlag = {
  datum: string;
  gp_nr: number;
  name_wochentag: string;
};

export default function TerminVorschlaege(props: {
  appProps: AppProps;
  signalRInstance: MutableRefObject<ISignalRConnector | null>;
  terminVorschlagAccepted: TerminVorschlag | null;
}) {
  const [formData] = useRecoilState(wizzardState);
  const [isLoading, setIsLoading] = useState(true);
  const [terminVorschlaege, setTerminVorschlaege] = useState<
    TerminVorschlag[] | null
  >(null);

  const [isCompleted, setIsCompleted] = useState<TerminVorschlag | null>(
    props.terminVorschlagAccepted ?? null
  );

  const [showModal, setShowModal] = useState(false);
  const [, setSendSMSReminder] = useState(false);
  const [smsReminderNumber, setSmsReminderNumber] = useState<string|null>(null);
  const [selectedTerminVorschlag, setSelectedTerminVorschlag] = useState<TerminVorschlag | null>(null);

  useEffect(() => {
    setIsCompleted(props.terminVorschlagAccepted);
  }, [props.terminVorschlagAccepted]);

  useEffect(() => {
    setSmsReminderNumber(formData.mobil);
  }, [formData.mobil]);

  useEffect(() => {
    if (
      !props.appProps.serviceUrl ||
      props.appProps.serviceUrl == null ||
      props.appProps.auth == null
    )
      return;

    const getTerminvorschlaege = () => {
      setIsLoading(true);

      return axios
        .get<TerminVorschlag[]>(
          `${props.appProps.serviceUrl}/Bvmu/Beitrittserklaerung/TerminVorschlaege`,
          { headers: { Authorization: props.appProps.auth } }
        )
        .then((resp: AxiosResponse<TerminVorschlag[]>) => {
          setIsLoading(false);
          setIsCompleted(resp.data.length === 1 ? resp.data[0] : null);
          setTerminVorschlaege(resp.data);
        });
    };

    getTerminvorschlaege();
  }, [props.appProps.auth, props.appProps.serviceUrl]);

  //group terminVorschlaege by date part of datum
  let groupedTerminVorschlaege = useMemo(() => terminVorschlaege?.reduce(
    (acc, terminVorschlag) => {
      const date = terminVorschlag.datum.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(terminVorschlag);
      return acc;
    },
    {} as { [key: string]: TerminVorschlag[] }
  ), [terminVorschlaege]);

  const handleSmsReminderChange = async (smsReminder: boolean) => {

    const confirmSaveTerminVorschlag = async (t: TerminVorschlag | null, sendSms: boolean, smsEmpf: string | null) => {
      if(!t) return;
  
      setIsLoading(true);
  
      await axios.post(
        `${props.appProps.serviceUrl}/Bvmu/Beitrittserklaerung/TerminVorschlaege`,
        { termin: t.datum, gp: t.gp_nr, sendSms: sendSms, smsEmpfaenger: smsEmpf },
        { headers: { Authorization: props.appProps.auth } }
      );
      setIsCompleted(t);
      setTerminVorschlaege([t]);
      setIsLoading(false);
  
      props.signalRInstance.current?.Client2GroupExcludeSelf({
        terminVorschlagAccepted: t,
      });
    };

    await confirmSaveTerminVorschlag(selectedTerminVorschlag, smsReminder, smsReminderNumber);
    setSendSMSReminder(smsReminder);
    setShowModal(false);
    
  };

  const handleSaveTerminVorschlag = (t: TerminVorschlag) => {
    setSelectedTerminVorschlag(t);
    setShowModal(true);
  };

  

  if (isLoading) return <Loader />;
  if (isCompleted)
    return (
      <div className="terminVorschlaege">
        <Row className="terminVorschlaege-header text-center">
          <Col>
            <h1>Terminvorschläge</h1>
            <p className="mt-5">
              Vielen Dank für Ihre Auswahl.
              <br />
              Sie haben sich erfolgreich für den Termin am{" "}
              <b>{moment(isCompleted.datum).format("DD.MM.YYYY")}</b> um{" "}
              <b>{moment(isCompleted.datum).format("HH:mm [Uhr]")}</b>{" "}
              entschieden.
            </p>
          </Col>
        </Row>
      </div>
    );

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>SMS Benachrichtigung</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Wünschen Sie eine SMS Benachrichtigung kurz vor dem Termin.</p>
          
          {isNullOrEmpty(formData.mobil) ? <>
          
            <Form.Group as={Col}>
              <Form.Control
                type="text"
                placeholder="Mobile Telefonnummer"
                pattern="[+0]\d{6,15}"
                minLength={5}
                maxLength={20}
                name="mobil"
                onChange={(e) => setSmsReminderNumber(e.target.value.replace(/[^\d+]/g, ''))}
                value={smsReminderNumber ?? ""}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie hier eine gültige Mobilfunknummer ein.<br/>
                 <small>(z.B. +491604567 oder 0163 4444 5555).</small>
              </Form.Control.Feedback>
            </Form.Group>

          </> : <>Mobilfunknummer: <b>{formData.mobil}</b></>}
        </Modal.Body>
        <Modal.Footer>
          <Button className="w-25" variant="secondary" onClick={() => handleSmsReminderChange(false)}>
            Nein
          </Button>
          <Button className="w-25" variant="primary" onClick={() => handleSmsReminderChange(true)} disabled={isNullOrEmpty(smsReminderNumber)}>
            Ja
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="terminVorschlaege">
        <Row className="terminVorschlaege-header text-center">
          <Col>
            <h1>Terminvorschläge</h1>
            <p>
              Bitte wählen Sie einen der Terminvorschläge für den BAV
              Beratungstermin.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          {groupedTerminVorschlaege &&
            Object.keys(groupedTerminVorschlaege).map((groupKey, index) => (
              <Col
                className="terminVorschlag text-center"
                key={"groupedTerminVorschlaege_" + index}
              >
                <Row>
                  <Col className="col-12 terminVorschlag-header">
                    <h5>
                      {groupedTerminVorschlaege[groupKey][0].name_wochentag}
                    </h5>
                    <h3>
                      {moment(
                        groupedTerminVorschlaege[groupKey][0].datum
                      ).format("DD.MM.YYYY")}
                    </h3>
                  </Col>

                  <Col className="terminVorschlag-entry d-grid gap-2">
                    {groupedTerminVorschlaege[groupKey].map(
                      (terminVorschlag) => (
                        <Button
                          name="terminvorschlag"
                          id={`terminvorschlag-${terminVorschlag.datum}`}
                          value={terminVorschlag.datum}
                          variant="outline-dark"
                          onClick={() => handleSaveTerminVorschlag(terminVorschlag)}
                          key={`terminvorschlag-${terminVorschlag.datum}`}
                        >
                          {moment(terminVorschlag.datum).format("HH:mm [Uhr]")}
                        </Button>
                      )
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
}
