import { FormControlProps, InputGroup } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useRecoilState } from "recoil";
import { wizzardState } from "../../atoms/wizzardState";
import { AppProps } from "../../App";
import { IMaskInput, ReactMaskProps } from 'react-imask';
import { HandleInputChangeEvent } from "../../pages/WizzardPage";
import { useState } from "react";

interface MaskedFormControlProps extends FormControlProps , Omit<ReactMaskProps<HTMLInputElement>, 'value'> {
  mask: string;
}

const MaskedFormControl: React.FC<MaskedFormControlProps> = ({ ...props }) => {
  return (
    <Form.Control as={IMaskInput} {...props} />
  );
};

export default function WizzardStepPayment(props: {
  handleInputChange: (e: HandleInputChangeEvent) => any,
  appProps: AppProps
}) {
  var [formData] = useRecoilState(wizzardState);
  const [isBicRequired, setIsBicRequired] = useState(false); // initial value

  //const ibanRef = useMask({ mask: '____ ____ ____ ____ ____ __', replacement: '_', showMask: true });

  var Capitalize = (value:string) => {
    return value.toUpperCase();
  }

  const handleIbanChanged = (value: string) => {
    props.handleInputChange({
      key: 'iban',
      value: value
    });

    setIsBicRequired(!value.startsWith("DE"));
  };
  
  return (
    <>
      <Row className="mb-3">
        <Col className="mr-1 form-inline col-12 col-md-6">
          <h3>Mitgliedsbeitrag</h3>
          <Form.Group className="mt-4">
            <Form.Check>
              <Form.Check.Input
                required
                type="radio"
                name="mitgliedsbeitrag"
                value="manuell"
                checked={formData.mitgliedsbeitrag === "manuell"}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
                id="mitgliedsbeitrag_manuell"
              />
              <Form.Check.Label htmlFor="mitgliedsbeitrag_manuell">
                <Form.Group>
                  <Form.Label>Ich zahle monatlich </Form.Label>
                  <InputGroup className="w-25 d-inline-flex" size="sm">
                    <InputGroup.Text>€</InputGroup.Text>
                    <Form.Control
                      name="mitgliedsbeitrag_value"
                      type="number"
                      min="16.50"
                      max="10000.00"
                      step="0.01"
                      required={formData.mitgliedsbeitrag === "manuell"}
                      value={formData.mitgliedsbeitrag === "manuell" ? formData.mitgliedsbeitrag_value ?? "" : ""}
                      onChange={props.handleInputChange}
                      disabled={props.appProps.mode === "gp"}
                    />
                    <Form.Control.Feedback type="invalid">
                      Bitte geben Sie einen gültigen Beitrag ein, mind. jedoch 16,50€.
                    </Form.Control.Feedback>
                  </InputGroup>
                  <Form.Label>
                    Mitgliedsbeitrag.
                    <br />
                    Der Beitrag wird nach Selbsteinschätzung festgelegt. Der
                    Mindestmitgliedsbeitrag beträgt zur Zeit € 16,50 monatlich.
                  </Form.Label>
                  <Form.Control.Feedback type="invalid">
                    Bitte wählen Sie eine der beiden Optionen aus.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Check.Label>

              <Form.Check.Input
                required
                type="radio"
                name="mitgliedsbeitrag"
                value="sonderaktion96"
                checked={
                  formData.mitgliedsbeitrag === "sonderaktion96" ||
                  formData.mitgliedsbeitrag === ""
                }
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
                id="mitgliedsbeitrag_sonderaktion96"
              />
              <Form.Check.Label htmlFor="mitgliedsbeitrag_sonderaktion96">
                <b>Befristete Sonderaktion {(formData.sofortentscheiderklausel) ? '(inkl. Sofortentscheiderklausel)' : ''}:</b> <br />
                Ich zahle <b>jährlich € 96,00</b> (entspricht 8,00€ monatlich).
              </Form.Check.Label>
              <Form.Control.Feedback type="invalid">
                Bitte wählen Sie eine der beiden Optionen aus.
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
          <hr />
          <p>
            Die Zustellung der Verbandsmitteilungen ist im Beitrag
            eingeschlossen.
          </p>
          <p>
            Die Mitgliedschaft beträgt 1 Jahr und verlängert sich
            stillschweigend um ein Jahr, wenn nicht mit einer Frist von einem
            Monat zum Monatsende gekündigt wird.
          </p>
        </Col>
        <Col className="md-6 col-12 col-md-6">
          <h3>SEPA-Firmenlastschrift-Mandat</h3>
          <Row className="mb-3 form-block">
            <Form.Group as={Col}>
              <Form.Label>IBAN</Form.Label>
              <MaskedFormControl
                required
                type="text"
                placeholder="DE"
                pattern="^[a-zA-Z]{2}(?:[0-9]){18,20}$"
                name="iban"
                mask='aa00 0000 0000 0000 0000 0000 0000'
                unmask={true}  // true|false|'typed'
                value={Capitalize(formData.iban ?? "")}
                onAccept={handleIbanChanged}
                disabled={props.appProps.mode === "gp"}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie eine gültige IBAN ein.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="w-100"></div>
            <Form.Group as={Col}>
              <Form.Label>BIC{isBicRequired || <>{" "}<small>(nur bei ausländischen IBANs erforderlich)</small></>}</Form.Label>
              <Form.Control
                type="text"
                placeholder="BIC"
                pattern="^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$"
                name="bic"
                value={formData.bic}
                onChange={props.handleInputChange}
                disabled={props.appProps.mode === "gp"}
                required={isBicRequired}
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie eine gültige BIC ein.
                <br />
                Sie können z.B. die Suche auf{" "}
                <a
                  target="bicSuche"
                  href="https://www.iban-pruefen.de/bic-rechner"
                >
                  www.iban-pruefen.de/bic-rechner
                </a>{" "}
                verwenden.
              </Form.Control.Feedback>
            </Form.Group>
            <hr className="mt-3" />
            <p>
              <b>Bund der Selbständigen, Landesverband NRW e.V.</b>,<br />
              Ferdinand-Porsche-Str. 1, 59439 Holzwickede
              <br />
              Gläubiger-Identifikationsnummer DE62ZZZ00000380645
            </p>
            <p>
              <b>Bundesvereinigung mittelständischer Unternehmer e.V.</b>,<br />
              Ferdinand-Porsche-Str. 1, 59439 Holzwickede
              <br />
              Gläubiger-Identifikationsnummer DE24ZZZ00000380650
            </p>
            <p>
              <b>Mandatsreferenz:</b> Erhalten Sie nach Antragsannahme
            </p>
          </Row>
        </Col>
      </Row>
    </>
  );
}
