import { ChangeEvent } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useRecoilState } from "recoil";
import { wizzardState } from "../../atoms/wizzardState";
import { AppProps } from "../../App";

export default function WizzardStepOrder(props: {
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => any,
  appProps: AppProps
}) {
  var [formData] = useRecoilState(wizzardState);
  
  return (
    <>
      <Row className="mb-3">
        <Col className="mr-1 form-inline">
          <Form.Check>
            <Form.Check.Input
              required
              type="checkbox"
              name="sepamandat"
              value="true"
              checked={formData.sepamandat}
              onChange={props.handleInputChange}
              disabled={props.appProps.mode === "gp"}
              id="sepamandat"
            />
            <Form.Check.Label htmlFor="sepamandat">
              <p>
                Ich ermächtige den <b>BDS NRW e.V.</b>, Ferdinand-Porsche-Str.
                1, 59439 Holzwickede / die <b>BVMU e.V.</b>,
                Ferdinand-Porsche-Str. 1, 59439 Holzwickede, Zahlungen von
                meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich
                mein Kreditinstitut an, die von dem <b>BDS NRW e.V.</b> / der{" "}
                <b>BVMU e.V.</b> auf mein Konto gezogenen Lastschriften
                einzulösen. <br />
                <small>
                  Hinweis: Dieses Lastschriftmandat dient nur dem Einzug von
                  Lastschriften, die auf Konten von Unternehmen gezogen sind.
                  Ich bin nicht berechtigt, nach der erfolgten Einlösung eine
                  Erstattung des belasteten Betrages zu verlangen. Ich bin
                  berechtigt, mein Kreditinstitut bis zum Fälligkeitstag
                  anzuweisen, Lastschriften nicht einzulösen.
                </small>
              </p>
            </Form.Check.Label>
            <Form.Control.Feedback type="invalid">
              Ihre Zustimmung zum SEPA-Lastschriftmandatseinzug ist für die
              Beantragung unerlässlich.
            </Form.Control.Feedback>
          </Form.Check>
          <hr />
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              name="newsletter"
              value="true"
              checked={formData.newsletter}
              onChange={props.handleInputChange}
              disabled={props.appProps.mode === "gp"}
              id="newsletter"
            />
            <Form.Check.Label htmlFor="newsletter">
              <p>
                Ich stimme dem Erhalt des Newsletters und des Magazins per
                E-Mail zu.
                <br />
                <small>
                  (Eine Abbestellung ist jederzeit aus den E-Mails heraus
                  möglich)
                </small>
              </p>
            </Form.Check.Label>
          </Form.Check>
          <hr />
          <Form.Check>
            <Form.Check.Input
              required
              type="checkbox"
              name="datenschutz"
              value="true"
              checked={formData.datenschutz}
              onChange={props.handleInputChange}
              disabled={props.appProps.mode === "gp"}
              id="datenschutz"
            />
            <Form.Check.Label htmlFor="datenschutz">
              <p>
                Ich habe die Datenschutzerklärung gelesen und akzeptiert.
                <br />
                <small>
                  (Die Datenschutzerklärung ist zu jeder Zeit auf der Seite des
                  BDS NRW unter{" "}
                  <a href="https://www.bds-nrw.de/datenschutz.php">
                    https://www.bds-nrw.de/datenschutz.php
                  </a>{" "}
                  einzusehen)
                </small>
              </p>
            </Form.Check.Label>
            <Form.Control.Feedback type="invalid">
              Ihr Zustimming zur Datenschutzerklärung ist für die Beantragung
              unerlässlich.
            </Form.Control.Feedback>
          </Form.Check>
          {(formData.sofortentscheiderklausel) ? (
            <>
              <hr />
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  name="sofortentscheiderklauselAnzeige"
                  value="true"
                  checked={true}
                  readOnly={true}
                  id="sofortentscheiderklauselAnzeige"
                />
                <Form.Check.Label htmlFor="sofortentscheiderklauselAnzeige">
                  <p>
                    Sofortentscheiderklausel
                    <br />
                    <small>
                      (Das Mitglied kann innerhalb von 60 Tagen schriftlich vom Beitritt zurücktreten und erhält den vollen Mitgliedsbeitrag zurück. Alle Vorteile stehen sofort zur Verfügung.)
                    </small>
                  </p>
                </Form.Check.Label>
              </Form.Check>
            </>
          ) : null}
        </Col>
      </Row>
    </>
  );
}
