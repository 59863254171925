import * as loaders from "react-spinners";

export default function Loader({ show = true }: { show?: boolean } = {}) {
  return (
    <loaders.PulseLoader
      color="#089FE1"
      cssOverride={{
        display: show ? "block" : "none",
        margin: "15% auto",
        textAlign: "center"
      }}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}
