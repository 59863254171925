import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { TerminVorschlag } from "../pages/components/TerminVorschlaege";

export type WizzardStateType = {
  anrede: number | null;
  /* step personaldata */
  fname: string;
  vname: string;
  nname: string;
  street: string;
  plz: string;
  ort: string;
  tel: string;
  mobil: string;
  email: string;
  anzahl_mitarbeiter: number;
  branche: string;
  gebdate: string;
  /* step payment */
  mitgliedsbeitrag: "sonderaktion96" | "manuell" | "";
  mitgliedsbeitrag_value: string;
  iban: string;
  bic: string;
  /* order */
  sepamandat: boolean;
  datenschutz: boolean;
  newsletter: boolean;
  /* internal */
  current_step: number;
  completed: boolean;
  /* internal */
  terminVorschlagAccepted: TerminVorschlag | null;
  antrag_doc_id: number | null;
  sofortentscheiderklausel?: boolean | null;
};
const { persistAtom } = recoilPersist({ storage: sessionStorage });

export const wizzardState = atom<WizzardStateType>({
  key: "wizzardState",
  default: {
    /* step personaldata */
    fname: "",
    anrede: null,
    vname: "",
    nname: "",
    street: "",
    plz: "",
    ort: "",
    tel: "",
    mobil: "",
    email: "",
    anzahl_mitarbeiter: 0,
    branche: "",
    gebdate: "",
    /* step payment */
    mitgliedsbeitrag: "sonderaktion96",
    mitgliedsbeitrag_value: "",
    iban: "",
    bic: "",
    /* order */
    sepamandat: false,
    datenschutz: false,
    newsletter: false,
    /* internal */
    current_step: 0,
    completed: false,
    terminVorschlagAccepted: null,
    antrag_doc_id: null,
    sofortentscheiderklausel: false
  },
  effects_UNSTABLE: [persistAtom],
});
